import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ConstantsService } from './constants.service';
import { map, tap } from 'rxjs/internal/operators'
import { FaqCategory } from '../models/faq-model';
import { OgMetaModel } from '../models/og-meta-model';
import { GetRequestCachingHttpClient } from './request-caching/get-request-caching-http-client';
import { ReferencesHelperService } from './references-helper.service';
import { PartnerTypeModel } from '../models/partner-type.model';
import { TrackModel } from '../models/track.model';
import { ScheduleEntryModel } from '../models/schedule-entry.model';
import { ProjectModel } from '../models/project.model';
import { EventModel } from '../models/event.model';
import { PersonModel } from '../models/person.model';

@Injectable({
    providedIn: 'root',
})
export class ApiService {

    constructor(private http: HttpClient,
                private constants: ConstantsService,
                private gRCHC: GetRequestCachingHttpClient,
                private refHelper: ReferencesHelperService) {
    }

    public addSubscriber(email: string): Observable<any> {
        const url = `${this.constants.api}/Subscriber/Add`;
        return this.http.post(url, {email: email});
    }

    public addTrack(code: string): Observable<any> {
        const url = `${this.constants.api}/ReferenceTrack/Add`;
        return this.http.post(url, {code: code});
    }

    public getSettings(): Observable<any> {
        const url = `${this.constants.api}/Setting/GetAll`;
        return this.gRCHC.get<{ data: any }>(url).pipe(map(r => r.data));
    }

    public getFaqs(): Observable<FaqCategory[]> {
        const url = `${this.constants.api}/Faq/GetAll`;
        return this.http.get<{ data: FaqCategory[] }>(url).pipe(map(r => r.data));
    }

    public getMetaData(id: string): Observable<OgMetaModel> {
        const url = `${this.constants.api}/OgMetadata/GetOne/${id}`;
        return this.http.get<{ data: OgMetaModel }>(url).pipe(map(r => r.data));
    }

    public async getPartners(): Promise<PartnerTypeModel[]> {
        const url = `${this.constants.api}/Partner/GetByTypes`;
        const partnerTypes = await this.http.get<{ data: PartnerTypeModel[] }>(url).pipe(map(r => r.data)).toPromise();
        const partnerTypes2 = JSON.parse(JSON.stringify(partnerTypes));
        this.refHelper.populateReferences(partnerTypes2);
        return partnerTypes2;
    }

    public async getTracks(): Promise<TrackModel[]> {
        const url = `${this.constants.api}/Track/GetAll`;
        const tracks = await this.http.get<{data: TrackModel[]}>(url).pipe(map(r => r.data)).toPromise();
        const tracks2 = JSON.parse(JSON.stringify(tracks));
        this.refHelper.populateReferences(tracks2);
        return tracks2;
    }

    public async getProjects(): Promise<ProjectModel[]> {
        const url = `${this.constants.api}/Project/GetAll`;
        const projects = await this.http.get<{ data: ProjectModel[] }>(url).pipe(map(r => r.data)).toPromise();
        return projects.filter(p => p.repository).sort((p1, p2) => {
            if (p1.isFinalist && !p2.isFinalist) {
                return -1;
            }
            if (p2.isFinalist && !p1.isFinalist) {
                return 1;
            }
            return 0;
        });
    }

    public async getEvents(): Promise<EventModel[]> {
        const url = `${this.constants.api}/Events/GetAll`;
        const events = await this.http.get<{data: EventModel[]}>(url).pipe(map(r => r.data)).toPromise();
        const events2 = JSON.parse(JSON.stringify(events));
        this.refHelper.populateReferences(events2);
        return events2;
    }

    public async getSetting(settingSlug: string, categoryName?: string): Promise<any> {
        const settings = await this.getSettings().toPromise();
        if (categoryName) {
            const category = settings[categoryName];
            return category && category[settingSlug] || undefined;
        }
        return settings[settingSlug];
    }

    public async getSettingOrThrow(settingSlug: string, categoryName?: string): Promise<any> {
        const setting = await this.getSetting(settingSlug, categoryName);
        if (typeof setting === 'undefined') {
            throw new Error('Required setting not found: ' + settingSlug);
        }
        return setting;
    }

    public getSchedule(): Observable<ScheduleEntryModel[]> {
        const url = `${this.constants.api}/ScheduleEntry/GetAll`;
        return this.http.get<{ data: ScheduleEntryModel[] }>(url).pipe(map(r => r.data));
    }

    public async getPeople(): Promise<PersonModel[]> {
        const url = `${this.constants.api}/People/GetAll`;
        const people = await this.http.get<{ data: PersonModel[] }>(url).pipe(map(r => r.data)).toPromise();
        const people2 = JSON.parse(JSON.stringify(people));
        this.refHelper.populateReferences(people2);
        return people2;
    }
}
