import { MlhBadgeComponent } from './components/mlh-badge/mlh-badge.component';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { AboutUsPageComponent } from './pages/about/about-us-page/about-us-page.component';
import { EventDetailsPageComponent } from './pages/event-details-page/event-details-page/event-details-page.component';
import { FaqPageComponent } from './pages/faq-page/faq-page.component';
import { HomePageComponent } from './pages/home-page/home-page/home-page.component';
import { HeaderMenuComponent } from './components/header-menu/header-menu.component';
import { HomePageBannerComponent } from './pages/home-page/components/home-page-banner/home-page-banner.component';
import { ReasonsToComeComponent } from './pages/home-page/components/reasons-to-come/reasons-to-come.component';
import { AttendanceComponent } from './pages/event-details-page/components/attendance/attendance.component';
import { CivicTracksComponent } from './pages/event-details-page/components/civic-tracks/civic-tracks.component';
import { TracksComponent } from './pages/event-details-page/components/tracks/tracks.component';
import { EventDetailsBannerComponent } from './pages/event-details-page/components/event-details-banner/event-details-banner.component';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgxPageScrollModule } from 'ngx-page-scroll';
import { FooterComponent } from './components/footer/footer.component';
import { PrivacyPolicyPageComponent } from './pages/privacy-policy-page/privacy-policy-page.component';
import { HomePageDetailsComponent } from './pages/home-page/components/home-page-details/home-page-details.component';
import { TrackingLandingPageComponent } from './pages/tracking-landing-page/tracking-landing-page.component';
import { PageResolver } from './resolvers/page.resolver';
import { CookiesPopupComponent } from './components/cookies-popup/cookies-popup.component';
import { PartnersComponent } from './pages/home-page/components/partners/partners.component';
import { PathOnlyTransferHttpCacheInterceptor } from './services/request-caching/PathOnlyTransferHttpCacheInterceptor';
import { UrlParse } from './services/url-parse.service';
import { MapComponent } from './pages/home-page/components/map/map.component';
import { TracksPageComponent } from './pages/tracks-page/tracks-page.component';
import { TracksBannerComponent } from './pages/tracks-page/components/tracks-banner/tracks-banner.component';
import { TracksListComponent } from './pages/tracks-page/components/tracks-list/tracks-list.component';
import { ProjectIdeasPageComponent } from './pages/project-ideas-page/project-ideas-page.component';
import { ProjectIdeasBannerComponent } from './pages/project-ideas-page/components/project-ideas-banner/project-ideas-banner.component';
import { ProjectIdeasListComponent } from './pages/project-ideas-page/components/project-ideas-list/project-ideas-list.component';
import { ScheduleComponent } from './pages/schedule/schedule.component';
import { AboutFirstEditionComponent } from './pages/about/about-first-edition/about-first-edition.component';
import { SettingsResolver } from './resolvers/settings-resolver.service';
import { ProjectsPageComponent } from './pages/projects-page/projects-page.component';
import { ProjectsResolver } from './resolvers/projects.resolver';
import { EventsPageComponent } from './pages/events-page/events-page.component';
import { PersonCardComponent } from './components/person-card/person-card.component';
import { AmbassadeBadgeComponent } from './components/ambassade-badge/ambassade-badge.component';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { PersonDetailsDialogComponent } from './components/person-details-dialog/person-details-dialog.component';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { EventsResolver } from './resolvers/events.resolver';
import { PeoplePageComponent } from './pages/people-page/people-page.component';
import { PeopleBannerComponent } from './pages/people-page/components/people-banner/people-banner.component';
import { PeopleListComponent } from './pages/people-page/components/people-list/people-list.component';
import { PeopleResolver } from './resolvers/people.resolver';
import { LuckyWheelComponent } from './pages/lucky-wheel/lucky-wheel.component';


const pages = [
    AboutUsPageComponent,
    EventDetailsPageComponent,
    FaqPageComponent,
    HomePageComponent,
    PrivacyPolicyPageComponent,
    AboutUsPageComponent,
    HomePageDetailsComponent,
    TrackingLandingPageComponent,
    TracksPageComponent,
    AboutFirstEditionComponent,
    ProjectsPageComponent,
    EventsPageComponent,
    PeoplePageComponent,
];

const components = [
    HeaderMenuComponent,
    FooterComponent,
    HomePageBannerComponent,
    ReasonsToComeComponent,
    AttendanceComponent,
    CivicTracksComponent,
    EventDetailsBannerComponent,
    TracksComponent,
    PartnersComponent,
    MlhBadgeComponent,
    AmbassadeBadgeComponent,
    MapComponent,
    TracksBannerComponent,
    TracksListComponent,
    ProjectIdeasPageComponent,
    ProjectIdeasBannerComponent,
    ProjectIdeasListComponent,
    PersonCardComponent,
    CookiesPopupComponent,
    ScheduleComponent,
    PersonDetailsDialogComponent,
    PeopleBannerComponent,
    PeopleListComponent,
];

@NgModule({
    declarations: [
        AppComponent,
        ...pages,
        ...components,
        LuckyWheelComponent,
    ],
    bootstrap: [AppComponent],
    imports: [
        BrowserModule.withServerTransition({ appId: 'unihack-website' }),
        NoopAnimationsModule,
        AppRoutingModule,
        FormsModule,
        HttpClientModule,
        NgxPageScrollModule,
        MatDialogModule,
    ],
    providers: [
        PageResolver,
        SettingsResolver,
        ProjectsResolver,
        EventsResolver,
        PeopleResolver,
        UrlParse,
        PathOnlyTransferHttpCacheInterceptor,
        { provide: HTTP_INTERCEPTORS, useExisting: PathOnlyTransferHttpCacheInterceptor, multi: true },
    ]
})
export class AppModule {
    constructor() {
    }
}
