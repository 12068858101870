import { Component, ElementRef, Inject, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { environment } from '../../../../../environments/environment';


declare var H: any;

@Component({
    selector: 'app-map',
    templateUrl: './map.component.html',
    styleUrls: ['./map.component.scss'],
})
export class MapComponent implements OnInit {

    public constructor(@Inject(PLATFORM_ID) private platformId: Object) {
        this.isBrowser = isPlatformBrowser(this.platformId);
    }

    @ViewChild('map', { static: true })
    public mapElement: ElementRef;
    private platform: any;
    private map: any;

    private hereAppId = 'oTjhgfoj8SGuyldAs3sq';
    public hereAppCode = 'PkKCn2YrecHICIurfykl3g';
    private zoom = 16.5;
    private location = {
        pin: {
            lat: 45.7653708,
            lng: 21.2274072,
        },
        center: {
            lat: 45.7653708,
            lng: 21.2274072,
        },
    };

    public loaded = false;
    private readonly isBrowser: boolean;

    private doAnimation = false;

    private wegoMapsLink = 'https://www.google.com/maps/place/United+Business+Center+0/@' + this.location.pin.lat + ',' + this.location.pin.lng + ',17z/data=!3m1!4b1!4m6!3m5!1s0x474567d805988835:0x994a1fed1b8bc842!8m2!3d45.7653708!4d21.2299821!16s%2Fg%2F11h6djy1qn?entry=ttu  ';

    public ngOnInit() {
        if (!this.isBrowser) {
            return;
        }
        this.checkHereMapsLoaded();
    }

    private checkHereMapsLoaded() {
        if (typeof H === 'undefined' || !H || !H.service || !H.service.Platform) {
            setTimeout(() => {
                this.checkHereMapsLoaded();
            }, 500);
            return;
        }
        this.buildPlatform();
        this.buildMap();
        this.putLocationMarker();
        this.putLogoMarker();
        if (this.doAnimation) {
            this.zoom = 19;
            this.animateZoom();
        }
    }

    private putUptRestaurantText() {
        const icon = new H.map.DomIcon('<div>' +
            '<img style="    width: 200px; transform: rotate(55deg) translate(-23%, 220%);" class="bottom-center-absolute-aligned"' +
            ' src="/assets/images/upt-restaurant.svg" /></div>');
        const marker = new H.map.DomMarker({lat: 45.748517, lng: 21.239956}, {icon: icon});
        this.map.addObject(marker);
    }

    private putLogoMarker() {
        const icon = new H.map.DomIcon('<div><a target="_blank" href="' + this.wegoMapsLink + '">' +
            '<img style="width: 125px; transform: translate(-50%,-45px);" class="bottom-center-absolute-aligned"' +
            ' src="/assets/images/logo-orange-purple.svg" /></a></div>');
        const marker = new H.map.DomMarker(this.location.pin, {icon: icon});
        this.map.addObject(marker);
    }

    private putLocationMarker() {
        const icon = new H.map.DomIcon('<div><a target="_blank" href="' + this.wegoMapsLink + '">' +
            '<img style="width: 42px;" class="bottom-center-absolute-aligned"' +
            ' src="/assets/images/map-pin-512px.png" /></a></div>');
        const marker = new H.map.DomMarker(this.location.pin, {icon: icon});
        this.map.addObject(marker);
    }

    private buildPlatform() {
        this.loaded = true;
        this.platform = new H.service.Platform({
            'app_id': this.hereAppId,
            'app_code': this.hereAppCode,
            useHTTPS: environment.production,
        });
    }

    private buildMap() {
        const defaultLayers = this.platform.createDefaultLayers();
        this.map = new H.Map(
            this.mapElement.nativeElement,
            defaultLayers.normal.map,
            {
                zoom: this.zoom,
                center: this.location.center,
            }
        );
        // (<any>window).layers = defaultLayers;
        // (<any>window).map = this.map;
    }

    private animateZoom() {
        const animationStep = 250;
        const increaseZoom = () => {
            this.zoom += 1;
            this.map.setZoom(this.zoom, true);
            if (this.zoom < 19) {
                setTimeout(() => {
                    increaseZoom();
                }, animationStep);
            } else {
                this.putUptRestaurantText();
            }
        };
        (<any>window).zoom = increaseZoom;
        const decreaseZoom = () => {
            this.zoom -= 1;
            this.map.setZoom(this.zoom, true);
            if (this.zoom > 3) {
                setTimeout(() => {
                    decreaseZoom();
                }, animationStep * 3);
            } else {
                // increaseZoom();
            }
        };
        decreaseZoom();
    }

}
