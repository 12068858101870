<div id="abtop"></div>
<div *ngFor="let detail of details"
     [ngStyle]="{'background': detail.background}"
     class="inner-shadow-top">
    <div class="container" [ngClass]="detail.settings.container"
         [ngStyle]="{'padding-top': heightService.getHeightPx}">
        <div class="banner-background"></div>
        <div class="row"></div>
        <div class="row offset-sm-1">
            <div class="col-10 col-md-8 d-flex-start d-lg-none">
                <img class="mb-5" alt="logo" [src]="detail.imageSrc"/>
            </div>
            <div class="d-none d-lg-block col-12 mb-2">
                <div [ngClass]="detail.settings.title">{{detail.title}}</div>
            </div>

            <div class="col-12 col-lg-5">
                <p class="description" [innerHTML]="detail.text"></p>
                <a target="_blank" [href]="detail.link"><button class="btn btn-primary orange-button">{{detail.readMore}}</button></a>
            </div>
            <div class="col-1"></div>
            <div class="col-lg-5 logo d-none d-lg-flex">
                <img alt="logo" [src]="detail.imageSrc"/>
            </div>
        </div>
        <div class="row"></div>
    </div>
</div>
