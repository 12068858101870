import { Component, OnInit } from '@angular/core';
import { HeaderMenuHeightService } from '../../../services/header-menu-height.service';

@Component({
    selector: 'app-about-page',
    templateUrl: './about-us-page.component.html',
    styleUrls: ['./about-us-page.component.scss'],
})
export class AboutUsPageComponent implements OnInit {
    public details = [];
    constructor(private heightService: HeaderMenuHeightService) {
        this.initializeDetails();
    }

    ngOnInit() {
    }

    private initializeDetails() {
        this.details = [
            {
                title: 'What is Liga AC?',
                imageSrc: '/assets/images/logos/liga-ac.svg',
                text: 'A student NGO from Timișoara, Romania with more than 400 volunteers.<br><br>' +
                    '<a href="http://itec.ligaac.ro" class="link-text" target="_blank">iTEC</a>, ' +
                    '<a href="http://labs.ligaac.ro" class="link-text" target="_blank">Liga AC LABS</a>, ' +
                    '<a href="http://robotec.ligaac.ro" class="link-text" target="_blank">RoboTEC</a>' +
                    '... and now UniHack! Everyday, we create opportunities for high school and university ' +
                    'students to learn in a different way.<br><br>' +
                    '<p class="d-none d-sm-block">Liga AC has become one of the biggest student organizations' +
                    ' from Romania, having over 1500 beneficiaries from all around the world every year.</p>',
                readMore: 'More about Liga AC...',
                settings: {
                    container: ['vertical-align', 'full-height-vh', 'padding-component', 'pb-sm-0', 'banner-background-container'],
                    title: ['titles'],
                },
                background: 'linear-gradient(240deg, #1E003B 0%, #47009C 100%)',
                link: 'https://ligaac.ro',
            },
            {
                title: 'What is BanatIT?',
                imageSrc: '/assets/images/logos/banat-it.svg',
                text: '<p>Banat IT is an NGO based in Timișoara, Romania which is conceived to keep' +
                    ' the IT community informed about various ' +
                    'opportunities to leverage this industry’s strengths as a major pole of the IT excellence.</p>' +
                    '<p class="d-none d-sm-block">Our mission is to continuously develop and support opportunity' +
                    ' creation for tech individuals and companies. ' +
                    'We achieve that by organising and promoting events primarily ' +
                    'aimed at the tech sector, whether it’s a meetup, a hackathon, a conference or informal gathering.</p>' +
                    '<p class="d-block d-sm-none">We organise and promote events primarily aimed at the tech sector, ' +
                    'whether it’s a meetup, a hackathon, a conference or informal gathering.</p>',
                readMore: 'More about Banat IT...',
                settings: {
                    container: ['vertical-align', 'padding-component'],
                    title: ['titles-local'],
                },
                background: 'linear-gradient(240deg, #6E67F2 0%, #8739E5 100%)',
                link: 'https://banatit.ro/',
            },
        ];
    }
}
