<div class="container-fluid banner banner-purple">
    <div class="container component banner-background-container">
        <div class="banner-background"></div>
        <div class="row">
            <div class="col-12 page-title">
                The Conference
            </div>

            <div class="col-12 workshops-grid">
                <div  class="workshop">
                    <div>
                        <div class="title">T.B.A.</div>
                        <div class="description"></div>
                    </div>
                    <div>
                        <app-person-card [person]="" class="z-index"></app-person-card>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="container-fluid banner banner-orange">
    <div class="container component banner-background-container">
        <div class="row">
            <div class="col-12 page-title pt-2">
                Mini-Events
            </div>

            <div class="col-12 workshops-grid">
                <div *ngFor="let wsh of talks" class="workshop">
                    <div>
                        <div class="title">{{ wsh.title }}</div>
<!--                        <div class="title">{{ hourExtractor(wsh.startTime) }} - {{ hourExtractor(wsh.endTime) }}  {{ dateExtractor(wsh.startTime) }}</div>-->
                        <div class="description">{{ wsh.description }}</div>
                    </div>
                    <div>
                        <app-person-card [lightbg]="true" [person]="wsh.person"
                                         class="z-index"></app-person-card>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="container-fluid banner banner-purple">
    <div class="container component banner-background-container">
        <div class="banner-background"></div>
        <div class="row">
            <div class="col-12 page-title pt-2">
                Workshops
            </div>

            <div class="col-12 workshops-grid">
                <div *ngFor="let wsh of workshops" class="workshop">
                    <div>
                        <div class="title">{{ wsh.title }}</div>
<!--                        <div class="title">{{ hourExtractor(wsh.startTime) }} - {{ hourExtractor(wsh.endTime) }}  {{ dateExtractor(wsh.startTime) }}</div>-->
                        <div class="description">{{ wsh.description }}</div>
                    </div>
                    <div>
                        <app-person-card [person]="wsh.person" class="z-index"></app-person-card>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
