import { Component, Input, OnInit } from '@angular/core';
import { HeaderMenuHeightService } from '../../../../services/header-menu-height.service';
import { GlobalSettings } from '../../../../models/settings.model';
declare let fbq: Function;

@Component({
    selector: 'app-banner',
    templateUrl: './home-page-banner.component.html',
    styleUrls: ['./home-page-banner.component.scss'],
})
export class HomePageBannerComponent implements OnInit {
    @Input()
    public settings: GlobalSettings;

    constructor(public heightService: HeaderMenuHeightService) {
    }

    sendFbEvent = () => fbq('track', 'RegistrationClick');
    ngOnInit() {
    }

}
